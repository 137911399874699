import Flicking from '@egjs/react-flicking';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import type { WillChangeEvent } from '@egjs/react-flicking';
import type { RefObject } from 'react';

import { EVENTS } from '~/components/Slider/Slider.constants';
import { maxBy } from '~/util/maxBy';

import type { GallerySliderPanelData } from '~/components/Gallery/Gallery.types';

import styles from '~/components/Gallery/GalleryCaption.module.scss';

export const GalleryCaption = ({
	className,
	defaultIndex = 0,
	fixedHeight,
	flickingRef,
	sliderPanels,
}: {
	className?: string,
	defaultIndex?: number,
	fixedHeight?: boolean,
	flickingRef: RefObject<Flicking>,
	sliderPanels: GallerySliderPanelData[],
}) => {
	const [currentIndex, setCurrentIndex] = useState(defaultIndex);

	const currentPanel = sliderPanels[currentIndex];

	const maxCaptionSliderPanel = maxBy(sliderPanels, (({
		sliderPanelComponentProps: {
			caption = '',
		},
	}) => caption.length)) || {};

	const {
		sliderPanelComponentProps: {
			caption: currentCaption = '',
		},
	} = currentPanel;

	const {
		sliderPanelComponentProps: {
			caption: maxCaption = '',
		},
	} = maxCaptionSliderPanel;

	function handleWillChange({ index }: WillChangeEvent) {
		setCurrentIndex(index);
	}

	useEffect(() => {
		flickingRef.current?.on?.(EVENTS.WILL_CHANGE, handleWillChange);

		return () => {
			flickingRef.current?.off?.(EVENTS.WILL_CHANGE, handleWillChange);
		};
	}, [flickingRef.current]);

	return (
		<div
			className={`${styles.galleryCaptionContainer} ${className}`}
			data-qa="gallery-caption"
		>
			{sliderPanels.map(({
				sliderPanelComponentProps: {
					caption = '',
				},
			}, index) => {
				const isCurrent = index === currentIndex;

				return (
					<span
						aria-hidden={!isCurrent ? true : undefined}
						className={
							classNames(styles.galleryCaption, styles.galleryCaptionFade, {
								[styles.galleryCaptionCurrent]: isCurrent,
								'tw-hidden': !isCurrent,
							})
						}
						key={`caption-${index}`}
					>
						{caption}
					</span>
				);
			})}
			{
				((fixedHeight && maxCaption) || currentCaption) && (
					<span
						aria-hidden
						className={
							classNames(styles.galleryCaption, styles.galleryCaptionPlaceholder)
						}
					>
						{fixedHeight ? maxCaption : currentCaption}
					</span>
				)
			}
		</div>
	);
};
